
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define([], function () {
      return (root.wplSlide = factory());
    });
  } else if (typeof exports === 'object') {
    module.exports = factory();
  } else {
    root.wplSlide = factory();
  }
}(this, function () {
  var root = this;
  var _document = root.document;
  var origBoxArr = new Array(), startOptions = new Array();
  if (!Element.prototype.remove) {
    Element.prototype.remove = function () {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
    };
  }
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
  }
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (css) {
      var node = this;
      while (node) {
        if (node.matches(css))
          return node;
        else
          node = node.parentElement;
      }
      return null;
    };
  }

  (function (DOMParser) {
    "use strict";
    var proto = DOMParser.prototype, nativeParse = proto.parseFromString;
    try {
      if ((new DOMParser()).parseFromString('', 'text/html')) {
        return;
      }
    } catch (ex) {
    }

    proto.parseFromString = function (markup, type) {
      if (/^\s*text\/html\s*(?:;|$)/i.test(type)) {
        var doc = _document.implementation.createHTMLDocument('');
        if (markup.toLowerCase().indexOf('<!doctype') > -1) {
          doc.documentElement.innerHTML = markup;
        } else {
          doc.body.innerHTML = markup;
        }
        return doc;
      } else {
        return nativeParse.apply(this, arguments);
      }
    };
  }(DOMParser));

  if (!Object.assign) {
    Object.defineProperty(Object, 'assign', {
      enumerable: false,
      configurable: true,
      writable: true,
      value: function (target) {
        'use strict';
        if (target === undefined || target === null) {
          throw new TypeError('Cannot convert first argument to object');
        }
        var to = Object(target);
        for (var i = 1; i < arguments.length; i++) {
          var nextSource = arguments[i];
          if (nextSource === undefined || nextSource === null) {
            continue;
          }
          var keysArray = Object.keys(Object(nextSource));
          for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
            var nextKey = keysArray[nextIndex];
            var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
            if (desc !== undefined && desc.enumerable) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
        return to;
      }
    });
  }

  var browser = {
    addEventListener: !!root.addEventListener,
    touch: ('ontouchstart' in root) || root.DocumentTouch && _document instanceof DocumentTouch,
    transitions: (function (temp) {
      var props = ['transitionProperty', 'WebkitTransition', 'MozTransition', 'OTransition', 'msTransition'];
      for (var i in props) {
        if (temp.style[props[i]] !== undefined) {
          return true;
        }
      }
      return false;
    })(_document.createElement('wplSlide'))
  };

  function buildElement(tag, attrs) {
    var _tag = _document.createElement(tag);
    if (typeof attrs === 'object' && Object.keys(attrs).length) {
      for (var key in attrs) {
        if (key.charAt(0) !== '_') {
          _tag.setAttribute(key, attrs[key]);
        }
      }
    }
    return _tag;
  }

  var events = {
    handleEvent: function (event) {
      switch (event.type) {
        case 'mouseup':
        case 'touchstart':
          this.close(event);
          break;
      }
    },
    close: function (event) {
      var pagination;
      if ((pagination = _document.querySelector('.pagination-select-block.open')) && !event.target.closest('.pagination-select-block')) {
        pagination.classList.remove('open');
      }
    }
  };

  if (browser.addEventListener) {
    if (browser.touch) {
      root.addEventListener('touchstart', events, false);
    } else {
      root.addEventListener('mouseup', events, false);
    }
  } else {
    root.onclick = events.close;
  }

  function wplSlide(container, options) {
    "use strict";
    var noop = function () {
    };
    var offloadFn = function (fn) {
      setTimeout(fn || noop, 0);
    };

    if (!container) {
      return;
    }

    var element, slides, slidePos, width, length, controlsBlock, dotsBlock, arrows, delay, row = 1, page = 1, go, view, countItem, clientWidth;

    element = container.children[0];

    var defaultOptions = {
      continuous: true,
      autoRestart: false,
      auto: 0,
      speed: 700,
      group: true,
      dots: true,
      maxDots: 10,
      arrows: true,
      bootstrap: true,
      draggable: true,
      loaderClass: 'loading-progress'
    };

    options = Object.assign(defaultOptions, options || {});

    var input_opt = container.getAttribute('data-options');
    if (input_opt) {
      options = Object.assign(options, JSON.parse(input_opt));
    }

    var initKey = container.getAttribute('data-slider-init') || new Date().getTime() + Math.random();
    if (container.getAttribute('data-slider-init')) {
      kill();
    } else {
      startOptions[initKey] = Object.assign({}, options);
    }

    origBoxArr[initKey] = element.cloneNode(true);
    container.setAttribute('data-slider-init', initKey);

    var minSizeView = {
      lg: 1200,
      md: 992,
      sm: 768,
      xs: 320
    };

    minSizeView = Object.assign(minSizeView, options.minSizeView);

    var index = parseInt(options.startSlide, 10) || 0;
    var speed = options.speed;

    function ajaxLoadedContent(pos, _url, _index) {

      if (_url === false || _url === true) {
        return;
      }

      while (slides[pos].firstChild) {
        slides[pos].removeChild(slides[pos].firstChild);
      }
      slides[pos].appendChild(getLoader());

      slide(pos);

      var _options = {
        method: options.pagination.method || 'GET',
        url: _url + (_url.indexOf('?') > 0 ? '&' : '?') + 'deltarow=' + Math.ceil(options.pagination.items_per_page % row) || 0,
      };

      var data = new FormData(), _data;
      if (_data = options.pagination.data) {
        for (var key in _data) {
          data.append(key, _data[key]);
        }
      }

      var xhr = new XMLHttpRequest();
      xhr.open(_options.method, _options.url, false);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.onreadystatechange = function () {
        if (this.readyState === this.DONE && this.onreadystatechange && this.status === 200) {
          xhr.onreadystatechange = null;

          var result = options.pagination.dataType === 'JSON' ? JSON.parse(xhr.responseText) : xhr.responseText;

          var parser = new DOMParser();
          var el = parser.parseFromString(result, "text/html");
          var _newContainer = el.querySelector(options.pagination.sliderClass);

          container.parentNode.replaceChild(_newContainer, container);
          container = _newContainer;
          element = container.children[0];

          setup(_index !== undefined ? _index : 1);
        }
      };

      xhr.send(data);
    }

    function group() {

      for (view in minSizeView) {
        if (root.innerWidth >= minSizeView[view]) {
          break;
        }
      }

      var _dataGroups = {};

      if (options.dataGroups) {
        if (options.dataGroups.all) {
          for (var key in options.dataGroups.all) {
            _dataGroups[key] = options.dataGroups.all[key];
          }
        }
        _dataGroups = Object.assign(_dataGroups, options.dataGroups[view]);
        if (_dataGroups.kill) {
          return false;
        }
      }

      var _items, _parentDiv = 0, _hidden = false, _columns = 12;
      var docFrag = _document.createDocumentFragment();


      var input_opt = container.getAttribute('data-options');
      if (input_opt) {
        var opt = JSON.parse(input_opt) || {};
        if (opt.pagination) {
          options.pagination = opt.pagination;
          page = opt.pagination.page;
        }
      }

      if (_dataGroups.pagination === false || (_dataGroups.pagination && typeof _dataGroups.pagination !== 'object')) {
        options.pagination = false;
      }

      countItem = element.children.length;

      if (options.pagination) {
        if (options.pagination.urlPrev) {
          var _loader = getLoader();
          _loader.classList.add('prev');
          docFrag.appendChild(_loader);
        }
        if (options.pagination.urlNext) {
          var _loader = buildElement('div', {'class': options.loaderClass, 'style': 'display:block;float:left;width:100%'});
          _loader.classList.add('next');
          element.appendChild(_loader);
        }
      }

      slides = element.children;
      length = slides.length;

      for (var i = 0; i < length; i++) {
        if (_parentDiv === 0) {
          var attrs = {'style': 'float:left; width:100%; position:relative'};
          if (_hidden) {
            attrs.class = 'hidden';
          }
          _items = buildElement('div', attrs);
        }

        if (_dataGroups && _dataGroups.count) {
          row = _dataGroups.count;
        } else if (options.bootstrap) {
          var _classList = slides[i].classList.toString();
          var _posCol = _classList.indexOf('col-' + view);
          if (_posCol !== -1) {
            _columns = parseInt(_classList.substr((_posCol + 7), 2)) || _columns;
          }
          row = (_dataGroups.row || 1) * (12 / _columns);
        }

        var elm = slides[i].cloneNode(true);

        _items.appendChild(elm);

        if ((i + 1) % row === 0) {
          _parentDiv = 0;
          _hidden = true;
        } else {
          _parentDiv++;
        }

        if ((_dataGroups.itemCount && (i + 1 === _dataGroups.itemCount)) || (i + 1 === length)) {
          docFrag.appendChild(_items);
          break;
        }
        if (_parentDiv === 0 || i + 1 === length) {
          docFrag.appendChild(_items);
        }
      }

      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }

      element.appendChild(docFrag);

      options.dots = _dataGroups.dots !== undefined ? _dataGroups.dots : options.dots;
      options.arrows = _dataGroups.arrows !== undefined ? _dataGroups.arrows : options.arrows;

      return true;
    }

    function getActiveDots() {


      if (options.dots && dotsBlock && controlsBlock) {

        var actives = dotsBlock.getElementsByClassName('active');

        for (var i = 0; i < actives.length; i++) {
          actives[i].classList.toggle('active');
        }

        var elm = controlsBlock.querySelector('[data-index="' + getPos() + '"][data-page="' + page + '"]');
        if (elm) {
          var _select = elm.closest('.pagination-select-block');
          if (_select) {

            var _prev = elm.closest('.clearfix').querySelector('.prev');
            if (options.pagination && options.pagination.urlPrev === false && index === 0) {
              _prev.classList.add('disabled');
            } else if (_prev.classList.contains('disabled')) {
              _prev.classList.remove('disabled');
            }

            var _next = elm.closest('.clearfix').querySelector('.next');
            if (options.pagination && options.pagination.urlNext === false && index === slides.length - 1) {
              _next.classList.add('disabled');
            } else if (_next.classList.contains('disabled')) {
              _next.classList.remove('disabled');
            }

            _select.querySelector('span.value').innerHTML = elm.innerHTML;
            if (_select.classList.contains('open')) {
              _select.classList.remove('open');
            }
          }
          elm.classList.add('active');
        }
      }

    }

    function setup(posSlide) {

      options = Object.assign({}, startOptions[initKey]);

      if (options.group && !group()) {
        root.addEventListener('resize', events, false);
        return;
      }

      slides = element.children;
      length = slides.length;

      if (slides.length === 1) {
        root.addEventListener('resize', events, false);
        return;
      }

      if (options.dots || options.arrows) {

        if (!controlsBlock) {
          controlsBlock = buildElement('div', {'class': 'wpl-slider-controls'});
          container.parentNode.insertBefore(controlsBlock, container.nextSibling);
        }

        if (options.dots) {
          if (length > 1) {

            dotsBlock = controlsBlock.querySelector('.wpl-slider-dots');

            if (!dotsBlock) {
              var _attrs = {};
              if (options.dots instanceof Object) {
                _attrs = Object.assign(_attrs, options.dots);
              }
              dotsBlock = buildElement('div', _attrs);
              dotsBlock.classList.add('wpl-slider-dots');
              controlsBlock.appendChild(dotsBlock);

            } else {
              while (dotsBlock.firstChild) {
                dotsBlock.removeChild(dotsBlock.firstChild);
              }
            }

            var _group = slides.length > options.maxDots ? true : false;
            if (options.dots instanceof Object) {
              _group = options.dots._group;
            }
            if (_group) {

              dotsBlock.classList.add('dots-pagination');

              var _wrapDiv = buildElement('div', {'class': 'clearfix'});

              var _prev = buildElement('span', {'class': 'prevnext prev', 'style': 'cursor:pointer'});
              _prev.onclick = prev;
              _prev.innerHTML = '<i class="fa fa-chevron-left"></i>';

              if (options.pagination && options.pagination.urlPrev === false && index === 0) {
                _prev.classList.add('disabled');
              }

              var _next = buildElement('span', {'class': 'next prevnext', 'style': 'cursor:pointer'});
              _next.onclick = next;



              _next.innerHTML = '<i class="fa fa-chevron-right"></i>';

              var _div = buildElement('div', {'class': 'pagination-select-block'});
              _div.onclick = function () {

                //jQuery && perfectScrollbar()
                if (typeof $ === 'function' && typeof $.fn.perfectScrollbar === 'function') {
                  var ul = $(this).find('ul');
                  setTimeout(function () {
                    ul.perfectScrollbar().scrollTop(ul.find('.active').index() * ul.find('.active').outerHeight());
                  }, 50);
                }

                this.classList.toggle('open');
              };

              var _span = buildElement('span', {'class': 'value-field'});
              var _span_child = buildElement('span', {'class': 'value'});
              var _i = buildElement('i', {'class': 'fa'});

              var _ul = buildElement('ul', {'class': 'pagination-select-list'});

              _wrapDiv.appendChild(_prev);

              var _page = 0;
              var _total = options.dots._total_items || countItem;
              var _items_per_page = options.dots._items_per_page || countItem;

              var key = 1, _breakTotal = Math.ceil(_total / row) + 1;

              while (_total > 0) {
                _page++;
                for (var i = 0; i < Math.floor(_items_per_page / row); i++) {
                  if (key === _breakTotal) {
                    break;
                  }
                  var _index = _page > 1 ? i + 1 : i;

                  var isActive = _page === page && _index === index;
                  var _li = buildElement('li', {
                    'class': 'item' + (isActive ? ' active' : ''),
                    'style': 'cursor:pointer',
                    'data-index': _index,
                    'data-page': _page
                  });

                  if (isActive) {
                    _span_child.innerHTML = key;
                  }

                  _li.onclick = function (e) {
                    e.stopPropagation();
                    var numGroup = +this.getAttribute('data-index');
                    var _page = +this.getAttribute('data-page');
                    if (_page === page) {
                      slide(numGroup);
                    } else {
                      var _url = options.dots._url.replace(':page', this.getAttribute('data-page'));
                      ajaxLoadedContent(0, _url, numGroup < 0 ? 0 : numGroup);
                    }
                  };
                  _li.innerHTML = key;
                  _ul.appendChild(_li);
                  key++;
                }

                _total = _total - _items_per_page;

                if (key > 500) {
                  console.info('page > 500');
                  break;
                }
              }

              if (options.pagination && options.pagination.urlNext === false) {
                _next.classList.add('disabled');
              }

              _span.appendChild(_span_child);
              _span.appendChild(_i);
              _div.appendChild(_span);
              _div.appendChild(_ul);

              _wrapDiv.appendChild(_div);
              _wrapDiv.appendChild(_next);
              dotsBlock.appendChild(_wrapDiv);

            } else {
              for (var i = 0; i < slides.length; i++) {
                var span = buildElement('span', {
                  'class': 'dot' + (index === i ? ' active' : ''),
                  'style': 'cursor:pointer',
                  'data-index': i,
                  'data-page': page
                });
                span.onclick = function () {
                  slide(parseInt(this.getAttribute('data-index')));
                };
                dotsBlock.appendChild(span);
              }
            }

          } else if (dotsBlock) {
            while (dotsBlock.firstChild) {
              dotsBlock.removeChild(dotsBlock.firstChild);
            }
          }
        }

        if (options.arrows && !controlsBlock.querySelector('.wpl-slider-arrows')) {
          var _attrs = {};
          if (options.arrows instanceof Object) {
            _attrs = Object.assign(_attrs, options.arrows);
          }
          arrows = buildElement('div', _attrs);
          arrows.classList.add('wpl-slider-arrows');
          var _prev = buildElement('span', {'class': 'prev arrow', 'style': 'cursor:pointer'});
          _prev.onclick = prev;
          _prev.innerHTML = '<i class="fa fa-chevron-left"></i>';

          var _next = buildElement('span', {'class': 'next arrow', 'style': 'cursor:pointer'});
          _next.onclick = next;
          _next.innerHTML = '<i class="fa fa-chevron-right"></i>';

          arrows.appendChild(_prev);
          arrows.appendChild(_next);
          controlsBlock.appendChild(arrows);
        }

      }

      if (slides.length < 2) {
        options.continuous = false;
      }

      if (browser.transitions && options.continuous && slides.length < 3) {
        element.appendChild(slides[0].cloneNode(true));
        element.appendChild(element.children[1].cloneNode(true));
        slides = element.children;
      }

      slidePos = new Array(slides.length);
      width = container.getBoundingClientRect().width || container.offsetWidth;
      element.style.width = (slides.length * width * 2) + 'px';

      var pos = slides.length;
      while (pos--) {
        var _slide = slides[pos];
        _slide.style.width = width + 'px';
        _slide.setAttribute('data-index', pos);
        _slide.classList.remove('hidden');
        if (browser.transitions) {
          _slide.style.left = (pos * -width) + 'px';
          move(pos, index > pos ? -width : (index < pos ? width : 0), 0);
        }
      }

      if (options.continuous && browser.transitions) {
        move(circle(index - 1), -width, 0);
        move(circle(index + 1), width, 0);
      }

      if (!browser.transitions) {
        element.style.left = (index * -width) + 'px';
      }

      addEventListener();

      delay = options.auto || 0;
      if (delay || options.autoRestart) {
        restart();
        container.onmouseover = stop;
        container.onmouseout = restart;
      }

      if (options.pagination && page > 1 && index === 0 && posSlide === undefined) {
        posSlide = 1;
      }

      if (posSlide !== undefined) {
        if (options.pagination && go === 'prev') {
          posSlide = +element.querySelector('.next').parentNode.getAttribute('data-index') - 1;
        }
        setTimeout(slide, 20, posSlide);
      }
    }

    function prev() {
      if (options.continuous) {
        slide(index - 1);
      } else if (index) {
        slide(index - 1);
      }
    }

    function next() {
      if (options.continuous) {
        slide(index + 1);
      } else if (index < slides.length - 1) {
        slide(index + 1);
      }
    }

    function circle(index) {
      return (slides.length + (index % slides.length)) % slides.length;
    }

    function getPos() {
      var currentIndex = index;
      if (currentIndex >= length) {
        currentIndex = currentIndex - length;
      }
      return currentIndex;
    }

    function slide(to, slideSpeed) {
      if (index === to) {
        return;
      }

      if (browser.transitions) {
        var direction = Math.abs(index - to) / (index - to);
        if (options.continuous) {
          var natural_direction = direction;
          direction = -slidePos[circle(to)] / width;
          if (direction !== natural_direction) {
            to = -direction * slides.length + to;
          }
        }
        var diff = Math.abs(index - to) - 1;
        var e = 0;
        while (diff--) {
          move(circle((to > index ? to : index) - diff - 1), width * direction, 0);
          if (e > 1000) {
            console.error('crash cycle is completed');
            break;
          }
          e++;
        }
        to = circle(to);
        move(index, width * direction, slideSpeed || speed);
        move(to, 0, slideSpeed || speed);
        if (options.continuous) {
          move(circle(to - direction), -(width * direction), 0);
        }
      } else {
        to = circle(to);
        animate(index * -width, to * -width, slideSpeed || speed);
      }
      index = to;

      getActiveDots();

      offloadFn(function () {
        runCallback(getPos(), slides[index], direction);
      });
    }


    function move(index, dist, speed) {
      translate(index, dist || 0, speed);
      slidePos[index || 0] = dist || 0;
    }

    function translate(index, dist, speed) {
      var slide = slides[index];
      var style = slide && slide.style;
      if (!style) {
        return;
      }
      style.webkitTransitionDuration = style.MozTransitionDuration = style.msTransitionDuration = style.OTransitionDuration = style.transitionDuration = speed + 'ms';
      style.webkitTransform = 'translate(' + dist + 'px,0)' + 'translateZ(0)';
      style.msTransform = style.MozTransform = style.OTransform = 'translateX(' + dist + 'px)';
    }

    function animate(from, to, speed) {
      if (!speed) {
        element.style.left = to + 'px';
        return;
      }
      var start = +new Date();
      var timer = setInterval(function () {
        var timeElap = +new Date() - start;
        if (timeElap > speed) {
          element.style.left = to + 'px';
          if (delay) {
            begin();
          }
          if (options.transitionEnd) {
            options.transitionEnd.call(event, getPos(), slides[index]);
          }
          clearInterval(timer);
          return;
        }
        element.style.left = (((to - from) * (Math.floor((timeElap / speed) * 100) / 100)) + from) + 'px';
      }, 4);
    }

    var interval;
    function begin() {
      interval = setTimeout(next, delay);
    }

    function stop() {
      delay = 0;
      clearTimeout(interval);
    }

    function restart() {
      stop();
      delay = options.auto || 0;
      begin();
    }

    function isMouseEvent(e) {
      return /^mouse/.test(e.type);
    }

    var clickEdges = function (event, fn) {
      var clickArea = parseFloat(options.clickArea) || false;
      if (!!delta.x) {
        setTimeout(fn);
      } else if (clickArea && event.layerX < container.offsetWidth * (clickArea / 100)) {
        prev();
      } else if (clickArea && event.layerX > container.offsetWidth * ((100 - clickArea) / 100)) {
        next();
      } else {
        setTimeout(fn);
      }
    };

    var start = {};
    var delta = {};
    var isScrolling, clickOnTags = new Array();
    var events = {
      handleEvent: function (event) {
        switch (event.type) {
          case 'touchstart':
          case 'mousedown':
            this.start(event);
            break;
          case 'mousemove':
          case 'touchmove':
            this.move(event);
            break;
          case 'mouseup':
          case 'mouseleave':
          case 'touchend':
            if (event.type === 'mouseup') {
              clickEdges(event, offloadFn(this.end(event)));
            } else {
              offloadFn(this.end(event));
            }
            break;
          case 'webkitTransitionEnd':
          case 'msTransitionEnd':
          case 'oTransitionEnd':
          case 'otransitionend':
          case 'transitionend':
            offloadFn(this.transitionEnd(event));
            break;
          case 'resize':
            if (container.clientWidth && clientWidth !== container.clientWidth) {
              page = 1;
              clientWidth = container.clientWidth;

              kill();
              setup();
            }
            break;
        }
        if (options.stopPropagation) {
          event.stopPropagation();
        }
      },
      start: function (event) {
        var touches;
        if (isMouseEvent(event)) {
          touches = event;
          event.preventDefault();
        } else {
          touches = event.touches[0];
        }
        start = {
          x: touches.pageX,
          y: touches.pageY,
          time: +new Date()
        };

        isScrolling = undefined;
        delta = {};
        if (isMouseEvent(event)) {
          element.addEventListener('mousemove', this, false);
          element.addEventListener('mouseup', this, false);
          element.addEventListener('mouseleave', this, false);
        } else {
          element.addEventListener('touchmove', this, false);
          element.addEventListener('touchend', this, false);
        }
      },
      move: function (event) {
        var touches;
        if (event.target.tagName === 'A') {
          clickOnTags.push(event.target);
          event.target.onclick = function () {
            return false;
          };
        }
        if (isMouseEvent(event)) {
          touches = event;
        } else {
          if (event.touches.length > 1 || event.scale && event.scale !== 1) {
            return;
          }
          if (options.disableScroll) {
            event.preventDefault();
          }
          touches = event.touches[0];
        }
        delta = {
          x: touches.pageX - start.x,
          y: touches.pageY - start.y
        };
        if (typeof isScrolling === 'undefined') {
          isScrolling = !!(isScrolling || Math.abs(delta.x) < Math.abs(delta.y));
        }
        if (!isScrolling) {
          event.preventDefault();
          stop();
          if (options.continuous) {
            translate(circle(index - 1), delta.x + slidePos[circle(index - 1)], 0);
            translate(index, delta.x + slidePos[index], 0);
            translate(circle(index + 1), delta.x + slidePos[circle(index + 1)], 0);
          } else {
            delta.x = delta.x / ((!index && delta.x > 0 || index === slides.length - 1 && delta.x < 0) ? (Math.abs(delta.x) / width + 1) : 1);
            translate(index - 1, delta.x + slidePos[index - 1], 0);
            translate(index, delta.x + slidePos[index], 0);
            translate(index + 1, delta.x + slidePos[index + 1], 0);
          }
        }
      },
      end: function (event) {

        var duration = +new Date() - start.time;
        var isValidSlide = Number(duration) < 250 && Math.abs(delta.x) > 20 || Math.abs(delta.x) > width / 2;
        var isPastBounds = !index && delta.x > 0 || index === slides.length - 1 && delta.x < 0;
        if (options.continuous) {
          isPastBounds = false;
        }
        var direction = delta.x < 0;
        if (!isScrolling) {
          if (isValidSlide && !isPastBounds) {
            if (direction) {
              if (options.continuous) {
                move(circle(index - 1), -width, 0);
                move(circle(index + 2), width, 0);
              } else {
                move(index - 1, -width, 0);
              }
              move(index, slidePos[index] - width, speed);
              move(circle(index + 1), slidePos[circle(index + 1)] - width, speed);
              index = circle(index + 1);
            } else {
              if (options.continuous) {
                move(circle(index + 1), width, 0);
                move(circle(index - 2), -width, 0);
              } else {
                move(index + 1, width, 0);
              }
              move(index, slidePos[index] + width, speed);
              move(circle(index - 1), slidePos[circle(index - 1)] + width, speed);
              index = circle(index - 1);
            }
            runCallback(getPos(), slides[index], direction);
          } else {
            if (options.continuous) {
              move(circle(index - 1), -width, speed);
              move(index, 0, speed);
              move(circle(index + 1), width, speed);
            } else {
              move(index - 1, -width, speed);
              move(index, 0, speed);
              move(index + 1, width, speed);
            }
          }
        }
        if (isMouseEvent(event)) {
          element.removeEventListener('mousemove', events, false);
          element.removeEventListener('mouseup', events, false);
          element.removeEventListener('mouseleave', events, false);
        } else {
          element.removeEventListener('touchmove', events, false);
          element.removeEventListener('touchend', events, false);
        }

        getActiveDots();

      },
      transitionEnd: function (event) {

        if (parseInt(event.target.getAttribute('data-index'), 10) === index) {
          if (options.pagination) {
            if (slides[index].classList.contains('prev')) {
              go = 'prev';
              ajaxLoadedContent(0, options.pagination.urlPrev);
            }
            if (slides.length === index + 1) {
              go = 'next';
              ajaxLoadedContent(1, options.pagination.urlNext);
            }
          }
          if (delay || options.autoRestart) {
            restart();
          }
          if (clickOnTags.length) {
            for (var i = 0; i <= clickOnTags.length; i++) {
              if (clickOnTags[i]) {
                clickOnTags[i].onclick = null;
              }
            }
            clickOnTags = new Array();
          }
          if (options.transitionEnd) {
            options.transitionEnd.call(event, getPos(), slides[index]);
          }
        }
      }
    };

    function getLoader() {
      var _loader = buildElement('div', {'style': 'float:left; width:100%; position:relative'});
      _loader.innerHTML = '<div class="' + options.loaderClass + '"></div>';
      return _loader;
    }


    function runCallback(pos, slide, dir) {
      if (options.callback) {
        options.callback(pos, slide, dir);
      }
    }

    function addEventListener() {
      if (browser.addEventListener) {

        if (browser.touch) {
          element.addEventListener('touchstart', events, false);
        }
        if (options.draggable) {
          element.addEventListener('mousedown', events, false);
        }
        if (browser.transitions) {
          element.addEventListener('webkitTransitionEnd', events, false);
          element.addEventListener('msTransitionEnd', events, false);
          element.addEventListener('oTransitionEnd', events, false);
          element.addEventListener('otransitionend', events, false);
          element.addEventListener('transitionend', events, false);
        }
        root.addEventListener('resize', events, false);

      } else {
        root.onresize = function () {
          setup();
        };
      }
    }

    function kill() {

      if (slides) {
        slide(0);
        stop();
      }

      if (browser.addEventListener) {
        element.removeEventListener('touchstart', events, false);
        element.removeEventListener('mousedown', events, false);
        element.removeEventListener('webkitTransitionEnd', events, false);
        element.removeEventListener('msTransitionEnd', events, false);
        element.removeEventListener('oTransitionEnd', events, false);
        element.removeEventListener('otransitionend', events, false);
        element.removeEventListener('transitionend', events, false);
        root.removeEventListener('resize', events, false);
      } else {
        root.onresize = null;
      }

      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }

      if (controlsBlock) {
        var arrows = controlsBlock.querySelector('.wpl-slider-arrows');
        if (arrows) {
          controlsBlock.removeChild(arrows);
        }

        var dotsBlock = controlsBlock.querySelector('.wpl-slider-dots');
        if (dotsBlock) {
          controlsBlock.removeChild(dotsBlock);
        }
      }

      element = origBoxArr[initKey].cloneNode(true);
      container.insertBefore(element, container.firstChild);

    }

    setup();

    return {
      slide: function (to, speed) {
        stop();
        slide(to, speed);
      },
      prev: function () {
        stop();
        prev();
      },
      next: function () {
        stop();
        next();
      },
      getNumSlides: function () {
        return length;
      },
      getPos: getPos,
      stop: stop,
      setup: setup,
      restart: restart,
      kill: kill
    };
  }
  if (root.jQuery || root.Zepto) {
    (function ($) {
      $.fn.wplSlide = function (params) {
        return this.each(function () {
          $(this).data('wplSlide', new wplSlide($(this)[0], params));
        });
      };
    })(root.jQuery || root.Zepto);
  }
  return wplSlide;
}));
